@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fc-body {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  box-shadow: inset 0 0 5em 1em #000;
  background: #4f4f4f;
  position: relative;
}

.fc-lines::before {
  content: "";
  top: 50%;
  transform: translatey(-50%);
  left: 0;
  height: 8px;
  background: #000;
  width: 100%;
  position: absolute;
}

.fc-lines::after {
  content: "";
  top: 0;
  left: 50%;
  transform: translatex(-50%);
  width: 8px;
  background: #000;
  height: 100vh;
  position: absolute;
}

.fc-screen {
  clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 0 100%, 0 0, 50% 0);
  background: #e1e1e1;
  position: absolute;
  height: calc(100vh + 100vw);
  width: calc(100vh + 100vw);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 0 100%, 0 0, 50% 0);
  }
  12.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0, 100% 100%, 0 100%, 0 0, 50% 0);
  }
  37.5% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      0 100%,
      0 0,
      50% 0
    );
  }
  62.5% {
    clip-path: polygon(50% 50%, 0 100%, 0 100%, 0 100%, 0 100%, 0 0, 50% 0);
  }
  87.5% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0, 50% 0);
  }
  100% {
    clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
  }
}

.fc-lines {
  box-shadow: inset 0 0 5em 1em #000;
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
}

.fc-number {
  font-size: 18rem;
  position: relative;
}

.fc-number::after,
.fc-number::before {
  content: "";
  border: #f7f7f7 solid 12px;
  border-radius: 50%;
  position: absolute;
}

.fc-number::before {
  width: 480px;
  height: 480px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fc-number::after {
  width: 400px;
  height: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
